.container {
  flex-grow: 1 !important;
  width: 100%;
  position: relative;
  overflow: visible;
  border-radius: 8px;
}
.containerOpen {
  display: block;
  width: 99.5%;
  color: rgb(72, 72, 72);
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 0px 24px -8px #8f8f8f;
}

.input {
  font-size: 1.7rem !important;
  margin-bottom: 0px !important;
  height: 4.5rem !important;
  padding: 16px !important;
  border: 0px !important;
}

.suggestionsList {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: -0.2px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 24px -8px #8f8f8f;
  clip-path: inset(0px -24px -24px -24px);
}
.suggestion {
  cursor: pointer;
  padding: 16px;
  margin-bottom: 0px;
  border-top: 1px solid #d8d8d8;
  background: white;
}
.suggestionHighlighted {
  background-color: #ddd;
}

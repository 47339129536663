body {
  font-size: 1.5rem;
  font-family: 'Graphik Web', 'San Francisco', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-feature-settings: 'liga', 'kern';
  margin: 0px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.no_user_select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.SlideInModal__Overlay {
  position: absolute;
  bottom: 0px;
  left: 10vw;
  min-height: 20rem;
  width: 80vw;
  opacity: 0;
  transform: translateY(100vh);
  transition: all 400ms ease-out;
  border: 1px solid black;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.SlideInModal__OverlayAfterOpen {
  opacity: 1;
  transform: translateY(0px);
  outline: none;
}
.SlideInModal__OverlayBeforeClose {
  opacity: 0;
  transform: translateY(100vh);
  transition: all 150ms ease-in;
}
